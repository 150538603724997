import { APP_INITIALIZER, enableProdMode, ErrorHandler, importProvidersFrom, inject } from "@angular/core";
import { environment } from "@environment/environment";

import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { bootstrapApplication, BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { AppRoutingModule } from "@app/app-routing.module";
import { AppComponent } from "@app/app.component";
import { Constants } from "@core/constants";
import { CoreModule } from "@core/core.module";
import { GlobalErrorHandler } from "@core/error/global-error-handler";
import { AuthInterceptor } from "@core/services/auth-interceptor.service";
import { AuthService } from "@core/services/auth.service";
import { ConfigParamsAndScreenTextsService } from "@core/services/config-params-and-screen-texts.service";
import { WINDOW_PROVIDERS } from "@core/services/window.service";
import { IonicRouteStrategy, provideIonicAngular } from "@ionic/angular/standalone";
import { WINDOW } from "@ng-web-apis/common";
import { provideTranslateService, TranslateLoader, TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { LanguageService } from "@shared/services/language.service";
import { NgxPermissionsModule } from "ngx-permissions";
import { EMPTY, map, Observable, switchMap } from "rxjs";

function initializeApp(
  authService: AuthService,
  configParamsAndScreenTextsService: ConfigParamsAndScreenTextsService,
  translateService: TranslateService,
  languageService: LanguageService,
  globalErrorHandler: GlobalErrorHandler,
): () => Observable<void> {
  const window = inject<Window>(WINDOW);

  if (window.location.href.includes("/#/")) {
    window.location.replace(window.location.href.replace("/#/", "/"));
  }
  if (window.location.href.includes("status_meldingen")) {
    return () => {
      globalErrorHandler.handleRejectedDatabaseLogin();
      return EMPTY;
    };
  } else {
    // console.log("Initializing PlanApp..., url=", window.location.href);
    return () => {
      const lang = languageService.determineLanguage();
      return translateService.use(lang).pipe(
        switchMap(() => authService.authorize()),
        switchMap(() => configParamsAndScreenTextsService.loadConfigParamsAndScreenTexts()),
        // tap(() => console.log('PlanApp initialized, Configuration loaded.')),
        map(() => void 0),
      );
    };
  }
}

const translateHttpLoaderFactory = (httpClient: HttpClient): TranslateHttpLoader => {
  return new TranslateHttpLoader(httpClient, `./assets/translations/`, ".json");
};

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule, NgxPermissionsModule.forRoot(), AppRoutingModule, CoreModule),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AuthService, ConfigParamsAndScreenTextsService, TranslateService, LanguageService, GlobalErrorHandler],
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    WINDOW_PROVIDERS,
    provideHttpClient(withInterceptorsFromDi()),
    provideIonicAngular({}),
    provideTranslateService({
      defaultLanguage: Constants.LANGUAGE_NL,
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
}).catch((err) => console.log(err));
