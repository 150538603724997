import { Component, computed, DestroyRef, inject, OnInit, Signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { NavigationEnd, Router, RouterEvent, RouterLink } from "@angular/router";
import { StatusMessageService } from "@app/features/plan/services/status-message.service";
import { ModalService } from "@app/shared/services/modal.service";
import { MainPage } from "@app/shared/types/pages";
import { PARAM_INSTITUTION } from "@core/domain/config-param-or-screen-text";
import { Role, User } from "@core/domain/user";
import { AuthService } from "@core/services/auth.service";
import { ConfigParamsAndScreenTextsService } from "@core/services/config-params-and-screen-texts.service";
import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
  IonRouterLink,
  IonSegment,
  IonSegmentButton,
  SegmentChangeEventDetail,
} from "@ionic/angular/standalone";
import { IonSegmentCustomEvent } from "@ionic/core";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { LanguageSelectorComponent } from "@shared/components/language-selector/language-selector.component";
import { PlanStateService } from "@shared/services/plan-state.service";
import { NgxPermissionsModule } from "ngx-permissions";
import { filter } from "rxjs";

@Component({
  selector: "pa-app-header",
  templateUrl: "./app-header.component.html",
  styleUrls: ["./app-header.component.scss"],
  standalone: true,
  imports: [
    RouterLink,
    NgxPermissionsModule,
    LanguageSelectorComponent,
    TranslateModule,
    IonRouterLink,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonButton,
    IonIcon,
    IonPopover,
    IonContent,
    IonList,
    IonItem,
  ],
})
export class AppHeaderComponent implements OnInit {
  selectedPage: MainPage = MainPage.PLAN;
  user: User | undefined;
  loginMenuOpen = false;
  customLogo = "";
  router = inject(Router);
  authService = inject(AuthService);
  planStateService = inject(PlanStateService);
  translateService = inject(TranslateService);
  modalService = inject(ModalService);
  plansAvailable: Signal<boolean> = computed(() => {
    const plans = this.planStateService.plans();
    return !!plans?.length;
  });
  protected readonly Role = Role;
  private configParamsAndScreenTextsService = inject(ConfigParamsAndScreenTextsService);
  private statusMessageService = inject(StatusMessageService);
  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    const institutionParam = this.configParamsAndScreenTextsService.getConfigParamOrScreenText(PARAM_INSTITUTION);
    let url = [""];

    if (institutionParam) {
      const logoPath = `/assets/images/customers/logo-${institutionParam.toLowerCase()}.png`;
      const defaultLogoPath = "/assets/images/customers/logo-osiris.png";

      const img = new Image();
      img.src = logoPath;

      img.onload = () => {
        this.customLogo = logoPath;
      };

      img.onerror = () => {
        this.customLogo = defaultLogoPath;
      };
    }

    this.authService
      .getUser$()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((user) => {
        this.user = user;
      });

    this.router.events
      .pipe(
        filter((event: unknown): event is RouterEvent => event instanceof RouterEvent),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((routerEvent: RouterEvent) => {
        switch (true) {
          case routerEvent instanceof NavigationEnd:
            url = routerEvent.url.split("/").filter((part) => part !== "");
            if (url?.length) {
              this.selectedPage = url[0] as MainPage;
            }
        }
      });
  }

  onLogout(): void {
    this.closeLoginMenu();
    if (this.planStateService.isPlanModified()) {
      this.modalService
        .handleConfirmModal("general.confirm.confirmTitle", "general.confirm.confirmQuestion")
        .subscribe((result) => {
          result && this.doLogout();
        });
    } else {
      this.doLogout();
    }
  }

  onMainTabClicked(event: IonSegmentCustomEvent<SegmentChangeEventDetail>): void {
    if (event.detail.value !== this.selectedPage) {
      this.statusMessageService.setMainPage(event.detail.value as MainPage);
    }
  }

  private doLogout(): void {
    // Navigate to the logout page, which will trigger the logout.
    this.router.navigate(["/session/logout"]);
  }

  private closeLoginMenu() {
    this.loginMenuOpen = false;
  }
}
